import { useState } from "react";
function PointComp(props){

    const x=props.x;
    const y=props.y;
    const Projektnummer=props.Projektnummer;
    const Projektbezeichnung=props.Projektbezeichnung;
    const Projektleiter=props.Projektleiter;
    const Controller=props.Controller;
    const Projektkategorie=props.Projektkategorie;
    const is=props.is;
    const setcurrentindex=props.setcurrentindex;
    const index=props.index;
    return(
        <>
        <div style={{position:'absolute',cursor:'pointer',top:y,right:x,width:'10px',height:'10px',borderRadius:'50%',backgroundColor:'green'}} onClick={(e)=>{
                   if(is){
            setcurrentindex(-1);
                   }
                   else{
                    setcurrentindex(index);

                   }

        }}>


        </div>
        {is && <div style={{position:'absolute',zIndex:'99999',border:'1px solid #00000040', top:y,right:x
    ,backgroundColor: 'white',borderRadius: '18px',
    padding: '5px'    
    }} >
            <div style={{textAlign:'right',cursor:'pointer'}} onClick={(e)=>{
            setcurrentindex(-1);
        }}>X</div>
            <p>{Projektnummer}</p>
            <p>{Projektbezeichnung}</p>
            <p>{Projektleiter}</p>
            <p>{Controller}</p>
            <p>{Projektkategorie}</p>
            </div>}
        </>
    )
}
export default PointComp;