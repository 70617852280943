

import { useState } from 'react';
import PointComp from './PointComp';
import image from './mapcousin.png';
function App() {
  var data=[{x:'519px',y:'334px',Projektnummer:'Projektnummer: G.016110302',Projektbezeichnung:'Projektbezeichnung: KP Lärm B Duisburg Photovoltaik',
  Projektleiter:'Projektleiter: Jan-Rudolf Möller',Controller:'Controller: Ivonne Scholz',Projektkategorie:'Projektkategorie: B'},

  {x:'433px',y:'289px',Projektnummer:'Projektnummer: G.016130014',Projektbezeichnung:'Projektbezeichnung: ESTW Wanne- Eickel Gbf',
  Projektleiter:'Projektleiter: Sebastian Stockhorst',Controller:'Controller: Ines Neugebauer',Projektkategorie:'Projektkategorie: A'},


  {x:'519px',y:'359px',Projektnummer:'Projektnummer: G.016130168',Projektbezeichnung:'Projektbezeichnung: ESTW Duisburg 2. Baustufe',
  Projektleiter:'Projektleiter: Jan-Rudolf Möller',Controller:'Controller: Astrid Berensmeier',Projektkategorie:'Projektkategorie: A'},

  {x:'50px',y:'250px',Projektnummer:'Projektnummer: G.016130197',Projektbezeichnung:'Projektbezeichnung: 2980 ESTW BS 2 Horn Bad Meinberg/Leopold',
  Projektleiter:'Projektleiter: Jens Gulich',Controller:'Controller: Max Bendorf',Projektkategorie:'Projektkategorie: A'},

  {x:'295px',y:'268px',Projektnummer:'Projektnummer: G.016130249',Projektbezeichnung:'Projektbezeichnung: ESTW Hamm 1.Baustufe Strecke 1760',
  Projektleiter:'Projektleiter: Irmgard Lünenschloß',Controller:'Controller: Anna Heiß',Projektkategorie:'Projektkategorie: A'},

  {x:'350px',y:'166px',Projektnummer:'Projektnummer: G.016130270',Projektbezeichnung:'Projektbezeichnung: 2014 / 2. BS Ausbau Bf Münster Zentrum N',
  Projektleiter:'Projektleiter: Dirk Liedtke',Controller:'Controller: Andrea Brauer',Projektkategorie:'Projektkategorie: B'},

  {x:'418px',y:'289px',Projektnummer:'Projektnummer: G.016130277',Projektbezeichnung:'Projektbezeichnung: EA Stw. Wanne-Eickel Gbf',
  Projektleiter:'Projektleiter: Sebastian Stockhorst',Controller:'Controller: Ines Neugebauer',Projektkategorie:'Projektkategorie: A'},

  {x:'307px',y:'286px',Projektnummer:'Projektnummer: G.016130281',Projektbezeichnung:'Projektbezeichnung: EA Stw. ESTW Hamm I 2. BS (Unna/Bönen/Howi)',
  Projektleiter:'Projektleiter: Lukas Joost',Controller:'Controller: Salih Özdemir',Projektkategorie:'Projektkategorie: A'},

  {x:'406px',y:'582px',Projektnummer:'Projektnummer: G.016130322',Projektbezeichnung:'Projektbezeichnung: 2651/Ausbau Betzdorf-Haiger #Hellertalba',
  Projektleiter:'Projektleiter: Jens Gulich',Controller:'Controller: Andrea Brauer',Projektkategorie:'Projektkategorie: B'},

  {x:'350px',y:'194px',Projektnummer:'Projektnummer: G.016130365',Projektbezeichnung:'Projektbezeichnung: Str. 2200; ESTW Bösensell',
  Projektleiter:'Projektleiter: Irmgard Lünenschloß',Controller:'Controller: Anna Heiß',Projektkategorie:'Projektkategorie: B'},

  {x:'356px',y:'205px',Projektnummer:'Projektnummer: G.016130366',Projektbezeichnung:'Projektbezeichnung: Str. 2200; ESTW Mecklenbeck',
  Projektleiter:'Projektleiter: Irmgard Lünenschloß',Controller:'Controller: Anna Heiß',Projektkategorie:'Projektkategorie: A'},

  {x:'380px',y:'210px',Projektnummer:'Projektnummer: G.016130419',Projektbezeichnung:'Projektbezeichnung: KLV Drehscheibe - Neubau Schwungeinfahrt',
  Projektleiter:'Projektleiter: Sebastian Stockhorst',Controller:'Controller: Ines Ivonne Scholz',Projektkategorie:'Projektkategorie: B'},

  {x:'420px',y:'210px',Projektnummer:'Projektnummer: G.016130424',Projektbezeichnung:'Projektbezeichnung: KSP-VzP Ratio 2980 ESTW Bf Lage 1. BS',
  Projektleiter:'Projektleiter: Jens Gulich',Controller:'Controller: Max Bendorf',Projektkategorie:'Projektkategorie: A'},
  
  {x:'212px',y:'188px',Projektnummer:'Projektnummer: G.016130837',Projektbezeichnung:'Projektbezeichnung: Robustes Netz Gütersloh & Isselhorst',
  Projektleiter:'Projektleiter: Irmgard Lünenschloß',Controller:'Controller: Brunhild-Christina Degenhard',Projektkategorie:'Projektkategorie: B'},

  {x:'527px',y:'253px',Projektnummer:'Projektnummer: G.016131124',Projektbezeichnung:'Projektbezeichnung: ESTW Rheinkamp',
  Projektleiter:'Projektleiter: Jan-Rudolf Möller ',Controller:'Controller: Anna Heiß',Projektkategorie:'Projektkategorie: A'},

  {x:'520px',y:'318px',Projektnummer:'Projektnummer: G.016131129',Projektbezeichnung:'Projektbezeichnung: KSP-ZBA DU-Ruhrort Ausbau Güterverkehrsa',
  Projektleiter:'Projektleiter: Sebastian Stockhorst ',Controller:'Controller: Ivonne Scholz',Projektkategorie:'Projektkategorie: B'},

  {x:'420px',y:'318px',Projektnummer:'Projektnummer: G.016221119',Projektbezeichnung:'Projektbezeichnung: KSP-ÜLS Bbf Sythen Neubau Gl. 11',
  Projektleiter:'Projektleiter: Irmgard Lünenschloß ',Controller:'Controller: Anna Heiß',Projektkategorie:'Projektkategorie: A'},

  {x:'203px',y:'422px',Projektnummer:'Projektnummer: T.016005403',Projektbezeichnung:'Projektbezeichnung: 2651/100,660 BÜ NASSAUISCHE STR. BURBACH',
  Projektleiter:'Projektleiter: Jens Gulich ',Controller:'Controller: Andrea Brauer',Projektkategorie:'Projektkategorie: B'},

  {x:'338px',y:'167px',Projektnummer:'Projektnummer: T.016019143',Projektbezeichnung:'Projektbezeichnung: 2206/2,516 ERN. BÜ LZH Erdbrüggenstraße,',
  Projektleiter:'Projektleiter: Sebastian Stockhorst ',Controller:'Controller: Ines Neugebauer',Projektkategorie:'Projektkategorie: B'},

  {x:'340px',y:'100px',Projektnummer:'Projektnummer: T.016025985',Projektbezeichnung:'Projektbezeichnung: 2983/4.594 Ern. BÜ UFERSTRAßE, BS1 Lage,',
  Projektleiter:'Projektleiter: Jens Gulich ',Controller:'Controller: Max Bendorf',Projektkategorie:'Projektkategorie: B'},

  {x:'511px',y:'325px',Projektnummer:'Projektnummer: T.016033173',Projektbezeichnung:'Projektbezeichnung: ESTW Duisburg Hbf 1. Baustufe,',
  Projektleiter:'Projektleiter: Jan-Rudolf Möller ',Controller:'Controller: Ivonne Scholz',Projektkategorie:'Projektkategorie: A'},

  {x:'478px',y:'332px',Projektnummer:'Projektnummer: T.016033174',Projektbezeichnung:'Projektbezeichnung: ESTW NORDSTRECKE 2.BS BÜ LZH Erdbrüggenstraße,',
  Projektleiter:'Projektleiter: Jan-Rudolf Möller ',Controller:'Controller: Andrea Brauer',Projektkategorie:'Projektkategorie: A'},

  {x:'254px',y:'518px',Projektnummer:'Projektnummer: T.016033481',Projektbezeichnung:'Projektbezeichnung: 2651/98,110 BÜ SCHEIDWALDSTR. IN BURBACH',
  Projektleiter:'Projektleiter: Jens Gulich ',Controller:'Controller: Andrea Brauer',Projektkategorie:'Projektkategorie: B'},

  {x:'268px',y:'518px',Projektnummer:'Projektnummer: T.016033482',Projektbezeichnung:'Projektbezeichnung: 2651/98,650 DELLWEG IN BURBACH',
  Projektleiter:'Projektleiter: Jens Gulich ',Controller:'Controller: Andrea Brauer',Projektkategorie:'Projektkategorie: C'},

  {x:'240px',y:'518px',Projektnummer:'Projektnummer: T.016033483',Projektbezeichnung:'Projektbezeichnung: 2651/98,880 BÜ BERGSTR. IN BURBACH',
  Projektleiter:'Projektleiter: Jens Gulich ',Controller:'Controller: Andrea Brauer',Projektkategorie:'Projektkategorie: B'},

  {x:'284px',y:'518px',Projektnummer:'Projektnummer: T.016033484',Projektbezeichnung:'Projektbezeichnung: 2651/100,110 BÜ ERZWEG IN BURBACH',
  Projektleiter:'Projektleiter: Jens Gulich ',Controller:'Controller: Andrea Brauer',Projektkategorie:'Projektkategorie: B'},

  {x:'254px',y:'530px',Projektnummer:'Projektnummer: T.016033485',Projektbezeichnung:'Projektbezeichnung: 2651/104,720 BÜ HÖHGARTEN IN BURBACH',
  Projektleiter:'Projektleiter: Jens Gulich ',Controller:'Controller: Andrea Brauer',Projektkategorie:'Projektkategorie: B'},
  
  {x:'268px',y:'530px',Projektnummer:'Projektnummer: T.016033486',Projektbezeichnung:'Projektbezeichnung: 2651/105,210 WERKSTR. IN BURBACH',
  Projektleiter:'Projektleiter: Jens Gulich ',Controller:'Controller: Andrea Brauer',Projektkategorie:'Projektkategorie: C'},

  {x:'210px',y:'500px',Projektnummer:'Projektnummer: T.016033487',Projektbezeichnung:'Projektbezeichnung: 2651/105,685 BÜ SANGSTR. IN BURBACH',
  Projektleiter:'Projektleiter: Jens Gulich ',Controller:'Controller: Andrea Brauer',Projektkategorie:'Projektkategorie: B'},

  {x:'333px',y:'313px',Projektnummer:'Projektnummer: T.016037252',Projektbezeichnung:'Projektbezeichnung: 2932/190,370 ERN. BÜ UELZENER DORFSTR.',
  Projektleiter:'Projektleiter: Lukas Joost ',Controller:'Controller: Salih Özdemir',Projektkategorie:'Projektkategorie: B'},

  {x:'474px',y:'507px',Projektnummer:'Projektnummer: T.016040749',Projektbezeichnung:'Projektbezeichnung: 2300 Elektr. Bf-Insel-Mülheim',
  Projektleiter:'Projektleiter: Jan-Rudolf Möller ',Controller:'Controller: Andrea Brauer',Projektkategorie:'Projektkategorie: B'},

  {x:'359px',y:'342px',Projektnummer:'Projektnummer: T.016043941',Projektbezeichnung:'Projektbezeichnung: 2550/156,165 ERNEUERUNG EÜ HÖRDER STRAßE',
  Projektleiter:'Projektleiter: Jens Gulich ',Controller:'Controller: Andrea Brauer',Projektkategorie:'Projektkategorie: B'},
  
  {x:'484px',y:'311px',Projektnummer:'Projektnummer: T.016044666',Projektbezeichnung:'Projektbezeichnung: FERNSTEUERUNG BF BOTTROP SÜD',
  Projektleiter:'Projektleiter: Jan-Rudolf Möller ',Controller:'Controller: Andrea Brauer',Projektkategorie:'Projektkategorie: B'},

  {x:'116px',y:'238px',Projektnummer:'Projektnummer: T.016048170',Projektbezeichnung:'Projektbezeichnung: 1760/120,041 Ern. BÜ Bad Lippspringer St',
  Projektleiter:'Projektleiter: Irmgard Lünenschloß ',Controller:'Controller: Brunhild-Christina Degenhard',Projektkategorie:'Projektkategorie: B'},

  {x:'98px',y:'152px',Projektnummer:'Projektnummer: T.016051719',Projektbezeichnung:'Projektbezeichnung: 2980/9,967 Erneuerung EÜ über die Bega',
  Projektleiter:'Projektleiter: Dirk Liedtke ',Controller:'Controller: Andrea Brauer',Projektkategorie:'Projektkategorie: B'},

 /* {x:'220px',y:'100px',Projektnummer:'Projektnummer: T.016051721',Projektbezeichnung:'Projektbezeichnung: 2980/18,033 Ern.EÜ Kreuzungsbauwerk Lage',
  Projektleiter:'Projektleiter: Dirk Liedtke ',Controller:'Controller: Andrea Brauer',Projektkategorie:'Projektkategorie: B'},
*/
  /*{x:'200px',y:'100px',Projektnummer:'Projektnummer: T.016060594',Projektbezeichnung:'Projektbezeichnung: 2980/20,396 Ern. BÜ Johannisweg,BS1 Lage',
  Projektleiter:'Projektleiter: Jens Gulich ',Controller:'Controller: Max Bendorf',Projektkategorie:'Projektkategorie: B'},
*/
  {x:'466px',y:'323px',Projektnummer:'Projektnummer: T.016060597',Projektbezeichnung:'Projektbezeichnung: 2550/156,875 BÜ ERNG KIRSCHBAUMSWEG',
  Projektleiter:'Projektleiter: Jens Gulich ',Controller:'Controller: Astrid Berensmeier',Projektkategorie:'Projektkategorie: B'},

  /*{x:'390',y:'230px',Projektnummer:'Projektnummer: T.016062994',Projektbezeichnung:'2100/34,708 Neubau EÜ über DEK Lüdingh.',
  Projektleiter:'Projektleiter: Dirk Liedtke ',Controller:'Controller: Astrid Berensmeier',Projektkategorie:'Projektkategorie: A'},
*/
  /*{x:'600px',y:'374px',Projektnummer:'Projektnummer: T.016064045',Projektbezeichnung:'Projektbezeichnung: 2932/199,871 BÜ-Bes. Grenzweg d. Ersatzw',
  Projektleiter:'Projektleiter: Lukas Joost ',Controller:'Controller: Salih Özdemir',Projektkategorie:'Projektkategorie: B'},
*/
  {x:'120px',y:'100px',Projektnummer:'Projektnummer: T.016064130',Projektbezeichnung:'Projektbezeichnung: 2231/2,522 Ern. BÜ Ostpreussenstr.',
  Projektleiter:'Projektleiter: Sebastian Stockhorst ',Controller:'Controller: Ines Neugebauer',Projektkategorie:'Projektkategorie: B'},

  {x:'100px',y:'100px',Projektnummer:'Projektnummer: T.016064984',Projektbezeichnung:'Projektbezeichnung: 2871/km 17,080 Aufweitung EÜ B 480',
  Projektleiter:'Projektleiter: Dirk Liedtke ',Controller:'Controller: Ines Neugebauer',Projektkategorie:'Projektkategorie: A'},

  {x:'502px',y:'420px',Projektnummer:'Projektnummer: T.016065451',Projektbezeichnung:'Projektbezeichnung: 2974/4,5 Ern.LzH BÜ Wirtschaftsweg Lange',
  Projektleiter:'Irmgard Lünenschloß ',Controller:'Controller: Brunhild-Christina Degenhard',Projektkategorie:'Projektkategorie: B'},

  {x:'515px',y:'385px',Projektnummer:'Projektnummer: T.016073582',Projektbezeichnung:'Projektbezeichnung: Oberleitungserneuerung Mülheim (R) West-',
  Projektleiter:'Projektleiter: Jan-Rudolf Möller ',Controller:'Controller: Astrid Berensmeier',Projektkategorie:'Projektkategorie: B'},

  {x:'502px',y:'309px',Projektnummer:'Projektnummer: T.016075625',Projektbezeichnung:'Projektbezeichnung: Ern. Bediensystem ESTW Ob.-Osterfeld Süd',
  Projektleiter:'Projektleiter: Jan-Rudolf Möller ',Controller:'Controller: Sven Matthias Golz',Projektkategorie:'Projektkategorie: B'},

  {x:'337px',y:'191px',Projektnummer:'Projektnummer: T.016076096',Projektbezeichnung:'Projektbezeichnung: 2200 Bf Mecklenbeck Erneuerung EWHA',
  Projektleiter:'Projektleiter: Irmgard Lünenschloß ',Controller:'Controller: Anna Heiß',Projektkategorie:'Projektkategorie: B'},

  {x:'397px',y:'227px',Projektnummer:'Projektnummer: T.016076097',Projektbezeichnung:'Projektbezeichnung: 2200 Nb Whz Bf Boesensell',
  Projektleiter:'Projektleiter: Irmgard Lünenschloß ',Controller:'Controller: Anna Heiß',Projektkategorie:'Projektkategorie: C'},

  {x:'449px',y:'302px',Projektnummer:'Projektnummer: T.016077339',Projektbezeichnung:'Projektbezeichnung: 2206/3,230 Ern. BÜ Hüllerstraße',
  Projektleiter:'Projektleiter: Sebastian Stockhorst ',Controller:'Controller: Ines Neugebauer',Projektkategorie:'Projektkategorie: B'},

  {x:'465px',y:'357px',Projektnummer:'Projektnummer: T.016077526',Projektbezeichnung:'Projektbezeichnung: Essen Hbf, Anbindung Ero an Ef',
  Projektleiter:'Projektleiter: Jan-Rudolf Möller ',Controller:'Controller: Sven Matthias Golz',Projektkategorie:'Projektkategorie: B'},

  {x:'361px',y:'304px',Projektnummer:'Projektnummer: T.016079382',Projektbezeichnung:'Projektbezeichnung: 2103 BA179,967 BÜ 15 Am Kapellenufer',
  Projektleiter:'Projektleiter: Lukas Joost ',Controller:'Controller: Salih Özdemir',Projektkategorie:'Projektkategorie: B'},
/*
  {x:'260px',y:'140px',Projektnummer:'Projektnummer: T.016079386',Projektbezeichnung:'Projektbezeichnung: 2910/0,380 Ern. BÜ1 Grenzweg',
  Projektleiter:'Projektleiter: Lukas Joost ',Controller:'Controller: Salih Özdemir',Projektkategorie:'Projektkategorie: B'},
*/
  {x:'346px',y:'325px',Projektnummer:'Projektnummer: T.016079387',Projektbezeichnung:'Projektbezeichnung: 2103 BA180,900 BÜ16 Schäferkampstr.',
  Projektleiter:'Projektleiter: Lukas Joost ',Controller:'Controller: Salih Özdemir',Projektkategorie:'Projektkategorie: B'},

  {x:'355px',y:'313px',Projektnummer:'Projektnummer: T.016079388',Projektbezeichnung:'Projektbezeichnung: 2103 BÜ 179,580 Sölder Str.',
  Projektleiter:'Projektleiter: Lukas Joost ',Controller:'Controller: Salih Özdemir',Projektkategorie:'Projektkategorie: B'},

  {x:'398px',y:'320px',Projektnummer:'Projektnummer: T.016080406',Projektbezeichnung:'Projektbezeichnung: 2932/200,354 Ern. BÜ 17 Provinzialstr ',
  Projektleiter:'Projektleiter: Lukas Joost ',Controller:'Controller: Salih Özdemir',Projektkategorie:'Projektkategorie: B'},

  {x:'630px',y:'226px',Projektnummer:'Projektnummer: T.016080729',Projektbezeichnung:'Projektbezeichnung: 2610/105,332 BÜ-Bes. d. EÜ Kalkarer Str.',
  Projektleiter:'Projektleiter: Jan-Rudolf Möller ',Controller:'Controller: Sven Matthias Golz',Projektkategorie:'Projektkategorie: A'},
/*
  {x:'360px',y:'140px',Projektnummer:'Projektnummer: T.016081693',Projektbezeichnung:'Projektbezeichnung: 2932/200,811 Ern. BÜ 18 Wilhelm-Lange',
  Projektleiter:'Projektleiter: Lukas Joost/ ',Controller:'Controller: Salih Özdemir',Projektkategorie:'Projektkategorie: B'},

  {x:'380px',y:'140px',Projektnummer:'Projektnummer: T.016083051',Projektbezeichnung:'Projektbezeichnung: Robustes Netz Sythen Nachrüstung Asig N2',
  Projektleiter:'Projektleiter: Irmgard Lünenschloß ',Controller:'Controller: Anna Heiß',Projektkategorie:'Projektkategorie: B'},

  {x:'400px',y:'140px',Projektnummer:'Projektnummer: T.016083266',Projektbezeichnung:'Projektbezeichnung: 2980/19,728 Ern. BÜ Heidenschestr.,BS1 L',
  Projektleiter:'Projektleiter: Jens Gulich ',Controller:'Controller: Max Bendorf',Projektkategorie:'Projektkategorie: B'},

  {x:'420px',y:'140px',Projektnummer:'Projektnummer: T.016083270',Projektbezeichnung:'Projektbezeichnung: 2983/0,605 Ern. BÜ Gasstr., BS1 Lage',
  Projektleiter:'Projektleiter: Jens Gulich ',Controller:'Controller: Max Bendorf',Projektkategorie:'Projektkategorie: B'},

  {x:'440px',y:'140px',Projektnummer:'Projektnummer: T.016083271',Projektbezeichnung:'Projektbezeichnung: 2984/7,328 Ern. BÜ Mühlenstr., BS1 Lage',
  Projektleiter:'Projektleiter: Jens Gulich',Controller:'Controller: Max Bendorf',Projektkategorie:'Projektkategorie: B'},

  {x:'460px',y:'140px',Projektnummer:'Projektnummer: T.016084499',Projektbezeichnung:'Projektbezeichnung: Erw. Stw Rhf um Schüsselsperre',
  Projektleiter:'Projektleiter: Sebastian Stockhorst ',Controller:'Controller: Ivonne Scholz',Projektkategorie:'Projektkategorie: C'},
*/
  {x:'541px',y:'244px',Projektnummer:'Projektnummer: T.016085553',Projektbezeichnung:'Alternative Antriebe, 2330, Xanten',
  Projektleiter:'Projektleiter: Jan-Rudolf Möller ',Controller:'Controller: Sven Matthias Golz',Projektkategorie:'Projektkategorie: B'},

  {x:'463px',y:'334px',Projektnummer:'Projektnummer: T.016085554',Projektbezeichnung:'Projektbezeichnung: Alternative Antriebe Essen Hbf',
  Projektleiter:'Projektleiter: Jan-Rudolf Möller ',Controller:'Controller: Sven Matthias Golz',Projektkategorie:'Projektkategorie: B'},
/*
  {x:'520px',y:'140px',Projektnummer:'Projektnummer: T.016087977',Projektbezeichnung:'Projektbezeichnung: KSP-ÜLS 2300/2291 zus. WV Mh-Heißen',
  Projektleiter:'Projektleiter: Jan-Rudolf Möller ',Controller:'Controller: Sven Matthias Golz',Projektkategorie:'Projektkategorie: B'},
*/
  {x:'404px',y:'289px',Projektnummer:'Projektnummer: T.016088319',Projektbezeichnung:'Projektbezeichnung: KSP-ÜLS 2650 GWB Bf GE bis WanneEickel',
  Projektleiter:'Projektleiter: Sebastian Stockhorst ',Controller:'Controller: Ines Neugebauer',Projektkategorie:'Projektkategorie: B'},

  {x:'426px',y:'298px',Projektnummer:'Projektnummer: T.016088321',Projektbezeichnung:'Projektbezeichnung: KSP-ÜLS 2200 zusWV EWAN-Abzw Baukau',
  Projektleiter:'Projektleiter: Sebastian Stockhorst ',Controller:'Controller: Ines Neugebauer',Projektkategorie:'Projektkategorie: B'},
/*
  {x:'580px',y:'140px',Projektnummer:'Projektnummer: T.016093039',Projektbezeichnung:'Projektbezeichnung: 2932/196,469 Ern. BÜ 12 Schwarzer Weg',
  Projektleiter:'Projektleiter: Lukas Joost ',Controller:'Controller: Salih Özdemir',Projektkategorie:'Projektkategorie: C'},
*/


];
var[currentindex,setcurrentindex]=useState(-1);
  return (
    <div className="App" style={{position:'relative',width:'fit-content',marginLeft:'auto',marginRight:'auto'}}>
    <img src={image} style={{width:'700px',display:'block',marginLeft:'auto',marginRight:'auto'}}/>
    {data.map((point,indexa)=>{
              
                return(
                <PointComp x={point.x} is={currentindex===indexa} index={indexa} setcurrentindex={setcurrentindex} y={point.y} Projektnummer={point.Projektnummer} Projektbezeichnung={point.Projektbezeichnung} 
                Projektleiter={point.Projektleiter} Controller={point.Controller} Projektkategorie={point.Projektkategorie}/>
                );             
                        
              })}
    </div>
  );
}

export default App;
